import React from 'react';

import './item-tile.css'
import { Link } from 'react-router-dom';

const ItemTile = ({ item }) => {

  return (
    <div className={`item-tile-blog-post-card`}>
      <div className="item-tile-container">
        <img
          alt={'image'}
          src={item.image}
          className="item-tile-image"
        />
        <div className="item-tile-container1">
          <span className="item-tile-text">{item.name}</span>
          <span className="item-tile-text1">{item.description}</span>
          <Link
            to={{
              pathname: '/product-ups/details',
              state: { item: item }
            }}
          >
            <button
              target="_blank"
              rel="noreferrer noopener"
              className="item-tile-link">
                <text className='button-text'>Know More</text>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ItemTile
