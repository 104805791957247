import React from "react";

import { Helmet } from "react-helmet";

import SectionHeading from "../../components/section-heading";
import CategoryCard from "../../components/category-card";
import Navbar from "../../components/navbar/navbar";
import "./home.css";
import Footer from "../../components/footer/footer";

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Neha Energy</title>
        <meta property="og:title" content="Neha Energy" />
      </Helmet>

      <Navbar />

      <div className="home-main">
        <div className="home-hero section-container">
          <div className="home-max-width max-width-container">
            <div className="home-hero1">
              <div className="home-container04">
                <div className="home-info">
                  <img
                    alt="Rectangle43271305"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMicgaGVpZ2h0PSc1Micgdmlld0JveD0nMCAwIDIgNTInIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+CjxyZWN0IHdpZHRoPScyJyBoZWlnaHQ9JzUyJyBmaWxsPSdibGFjaycgZmlsbC1vcGFjaXR5PScwLjUnLz4KPC9zdmc+Cg=="
                    className="home-image1"
                  />
                  <span className="home-text16">
                    Seamless Power, Limitless Potential
                  </span>
                </div>
                <h1 className="home-text17 Heading-1">
                  <span> Uninterrupted</span>
                  <br></br>
                  <span>resilience,</span>
                  <br></br>
                  <span>empowering</span>
                  <br></br>
                  <span>tomorrow</span>
                </h1>
                <div className="home-container05">
                  <span className="home-text25">
                    EMPOWER, ELEVATE, EXCEL: UNINTERRUPTED
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </div>
                <div className="home-btn-group">
                  <button className="home-button button">
                    Request a Consultation
                  </button>
                </div>
              </div>
              <img
                alt="image23271449"
                src="/svgs/ups_servers-600h.png"
                loading="lazy"
                className="home-image2"
              />
            </div>
          </div>
        </div>
        <div className="section-container column">
          <div className="max-width-container">
            <SectionHeading
              heading="OUR CATEGORIES"
              subtitle="Explore our wide range that meets your needs"
            ></SectionHeading>
            <div className="home-cards-container">
              <CategoryCard
                name="UPS"
                image_src="/svgs/aurora_600va___1000va_inbuilt-1-1500w.png"
                category_img="/svgs/aurora_600va___1000va_inbuilt-1-1500w.png"
                rootClassName="category-card-root-class-name"
              ></CategoryCard>
              <CategoryCard
                name="Network &amp; Server Rack"
                image_src="/svgs/eaton%20enclosure%20racks-900h.png"
              ></CategoryCard>
              <CategoryCard
                name="Power Distribution Unit"
                image_src="/svgs/eaton_9e_1-2-3_kva-1500w.png"
                category_img="/svgs/eaton_9e_1-2-3_kva-1500w.png"
              ></CategoryCard>
              <CategoryCard
                name="Automatic Transfer Switch"
                image_src="/svgs/eaton_dx-rt-06_and_10_kw-1500w.png"
                category_img="/svgs/eaton_dx-rt-06_and_10_kw-1500w.png"
              ></CategoryCard>
              <CategoryCard
                name="Stabilizer"
                image_src="/svgs/eaton_dxrt_ups_brochure_15-20kva-1-1500w.png"
                category_img="/svgs/eaton_dxrt_ups_brochure_15-20kva-1-1500w.png"
              ></CategoryCard>
            </div>
          </div>
          <div className="home-banner">
            <div className="home-container06">
              <h3 className="home-text26 Heading-3">NEHA</h3>
              <span className="home-text27"> ENERGY SOLUTIONS</span>
            </div>
          </div>
          <div className="home-container07 max-width-container">
            <div className="home-container08">
              <span className="home-text28">
                Tailored solutions to meet the unique needs of your business
              </span>
              <button className="home-button1 button">Explore Now</button>
            </div>
          </div>
        </div>
        <div className="home-trending-items section-container">
          <div className="max-width-container">
            <SectionHeading></SectionHeading>
            <div className="home-gallery">
              <div className="home-left1">
                <img
                  alt="image"
                  src="/svgs/eaton%20enclosure%20racks-900h.png"
                  className="home-image3"
                />
              </div>
              <div className="home-right1">
                <div className="home-top">
                  <div className="home-left2">
                    <img
                      alt="image"
                      src="/svgs/eaton_9e_1-2-3_kva-1500w.png"
                      className="home-image4"
                    />
                  </div>
                </div>
                <div className="home-bottom">
                  <div className="home-left3">
                    <img
                      alt="image"
                      src="/svgs/aurora_600va___1000va_inbuilt-1-1500w.png"
                      className="home-image5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.3807800180025!2d72.58167877622887!3d23.046497515433188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8527ca83b1d1%3A0xc16c1d97d7778876!2sNeha%20Energy%20Solutions!5e0!3m2!1sen!2sin!4v1693902558163!5m2!1sen!2sin"
          allowFullScreen
          className="home-iframe"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
