import React from "react";
import './footer.css';

const Footer = () => {

    return (

        <div className="home-footer">
            <div className="home-max-width3 max-width-container">
                <footer className="home-footer1">
                    <div className="home-container09">
                        <span className="home-text29">Contact Us</span>
                        <div className="home-container10">
                            <h3 className="home-text30">
                                <span className="Heading-3">NEHA ENERGY SOLUTIONS</span>
                                <br></br>
                            </h3>
                            <span className="home-text33">
                                <span>
                                    Neha Energy Solutions, D-315, Sumel Business Park 6, Above
                                    Central
                                </span>
                                <br></br>
                                <span>
                                    Bank Of India, Dudheshwar, Ahmedabad - 380004, Gujrat, India
                                </span>
                                <br></br>
                            </span>
                            <span className="home-text38">
                                <span>+91 9819407218</span>
                                <br></br>
                                <span>+91 7400407218</span>
                                <br></br>
                            </span>
                            <span className="home-text43">
                                <span>sales@nehaenergy.in</span>
                                <br></br>
                            </span>
                        </div>
                    </div>
                    <div className="home-links-container">
                        <div className="home-container11">
                            <span className="home-text46">Products</span>
                            <span className="home-text47">UPS</span>
                            <span className="home-text48">Network &amp; Server Rack</span>
                            <span className="home-text49">
                                <span>Power Distribution Unit</span>
                                <br></br>
                            </span>
                            <span className="home-text52">Automatic Transfer Switch</span>
                            <span className="home-text53">Stabilizer</span>
                            <span className="home-text54">
                                Electrical Fire Prevention Device
                            </span>
                        </div>
                        <div className="home-container12">
                            <span className="home-text55">About</span>
                            <span className="home-text56">Founder</span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer;