import React from 'react'

import { Helmet } from 'react-helmet'

import './about.css'
import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/footer'

const About = () => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Neha Energy</title>
        <meta property="og:title" content="About - Neha Energy" />
      </Helmet>
      
      <Navbar />

      <div className="about-main">
        <div className="section-container column">
          <div className="about-banner">
            <div className="about-container04">
              <h3 className="about-text10 Heading-3">PANKAJ KOTHARI</h3>
              <span className="about-text11">FOUNDER</span>
            </div>
          </div>
          <div className="about-container05 max-width-container">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fFByb2ZpbGV8ZW58MHx8fHwxNjkzNzc2NjU1fDA&amp;ixlib=rb-4.0.3&amp;w=200"
              className="about-image1"
            />
            <span className="about-text12">
              &quot; We believe in Quality &quot;
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About
