import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Home from './views/home/home'
import About from './views/about/about'

import ProductUps from './views/product-ups/product-ups'
import ProductUpsDetails from './views/product-ups-details/product-ups-details'
// import NotFound from './views/not-found/not-found'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Home} exact path="/" />
        <Route component={About} exact path="/about" />
        <Route component={ProductUps} exact path="/product-ups" />
        <Route component={ProductUpsDetails} exact path="/product-ups/details" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
