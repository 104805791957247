import React from 'react'

import { Helmet } from 'react-helmet';

import './product-ups-details.css'

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { useLocation } from 'react-router-dom';

const ProductUpsDetails = () => {

  const location = useLocation();
  const { item } = location.state;

  const downloadPdf = () => {
    pdfLink.click();
  }

  return (
    <div className="product-ups-container">
      <Helmet>
        <title>UPS</title>
        <meta property="og:title" content="Product-Ups - Neha Energy" />
      </Helmet>
      <Navbar />
      <div className="product-ups-main">
        <div className="product-ups-hero section-container">
          <div className="product-ups-max-width max-width-container">
            <img src={item.image} className='product-image' />
            <h1 className='product-title'>{item.name}</h1>

            <p className='product-description'>{item.description}</p>

            <div className='attributeContainer product-ups-max-width max-width-containe'>

              <div className='card'>
                <h3 className='attributeText'>{item.powerRating}</h3>
              </div>

              <div className='card'>
                <h3 className='attributeText'>{item.voltage}</h3>
              </div>

              <div className='card'>
                <h3 className='attributeText'>{item.config}</h3>
              </div>

            </div>

            <div className='attributeContainer2 product-ups-max-width max-width-containe'>

              <div className='card'>
                <h6 className='attributeTitle'>Power Rating</h6>
              </div>

              <div className='card'>
                <h6 className='attributeTitle'>Voltage</h6>
              </div>

              <div className='card'>
                <h6 className='attributeTitle'>Config</h6>
              </div>

            </div>

            <h1 className='product-title spaced-left'>Features</h1>

            {
              item.features.map(feature => <p className='product-feature'>▪️  {feature}</p>)
            }

            <h1 className='product-title spaced-left'>Applications</h1>

            {
              item.applications.map(app => <p className='product-feature'>▪️  {app}</p>)
            }

            <a id="pdfLink" href={item.pdf} download style={{ display: 'none' }}></a>

            <button id="downloadButton" className='pdfButton' onClick={downloadPdf}>
              <span className='product-button-text'>Download PDF</span>
            </button>

          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ProductUpsDetails
