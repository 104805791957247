import axios from 'axios';

//Server Url
const baseURL = 'https://neha-energy.cyclic.app';
// const baseURL = 'http://192.168.31.144:7000';
const headers = {
    'Content-Type': 'application/json',
};

const client = axios.create({ baseURL, headers });

const request = ({ method, url, data, ...config }) => {
    return client.request({ method, url, data, ...config });
};

export const networkService = { request };