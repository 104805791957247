import React from 'react';

import './navbar.css';

import { Link, useHistory } from 'react-router-dom';

const Navbar = () => {

    const history = useHistory();

    return (

        <div className="home-navbar">
            <header data-role="Header" className="home-header max-width-container">
                <div className="home-container01">
                    <div className="home-navbar1">
                        <div className="home-container02"></div>
                        <div className="home-middle">
                            <div className="home-left">
                                <div onClick={() => history.push('/')}>
                                    <span className="home-text navbar-link">HOME</span>
                                </div>
                                <div
                                    data-thq="thq-dropdown"
                                    className="home-thq-dropdown list-item"
                                >
                                    <div
                                        data-thq="thq-dropdown-toggle"
                                        className="home-dropdown-toggle"
                                    >
                                        <span className="home-text01 navbar-link">PRODUCTS</span>
                                    </div>
                                    <ul
                                        data-thq="thq-dropdown-list"
                                        className="home-dropdown-list"
                                    >
                                        <li
                                            data-thq="thq-dropdown"
                                            className="home-dropdown list-item"
                                        >
                                            <div onClick={() => history.push("/product-ups")}>
                                                <div
                                                    data-thq="thq-dropdown-toggle"
                                                    className="home-dropdown-toggle1"
                                                >

                                                    <span className="home-text02">UPS</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            data-thq="thq-dropdown"
                                            className="home-dropdown1 list-item"
                                        >
                                            <div
                                                data-thq="thq-dropdown-toggle"
                                                className="home-dropdown-toggle2"
                                            >
                                                <div onClick={() => history.push("/product-ups")}>
                                                    <span className="home-text03">
                                                        Network &amp; Server Rack
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            data-thq="thq-dropdown"
                                            className="home-dropdown2 list-item"
                                        >
                                            <div
                                                data-thq="thq-dropdown-toggle"
                                                className="home-dropdown-toggle3"
                                            >
                                                <div onClick={() => history.push("/product-ups")}>
                                                    <span className="home-text04">
                                                        Power Distribution Unit
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            data-thq="thq-dropdown"
                                            className="home-dropdown3 list-item"
                                        >
                                            <div
                                                data-thq="thq-dropdown-toggle"
                                                className="home-dropdown-toggle4"
                                            >
                                                <div onClick={() => history.push("/product-ups")}>
                                                    <span className="home-text05">
                                                        Automatic Transfer Switch
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            data-thq="thq-dropdown"
                                            className="home-dropdown4 list-item"
                                        >
                                            <div
                                                data-thq="thq-dropdown-toggle"
                                                className="home-dropdown-toggle5"
                                            >
                                               <div onClick={() => history.push("/product-ups")}>
                                                    <span className="home-text06">Stabilizer</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li
                                            data-thq="thq-dropdown"
                                            className="home-dropdown5 list-item"
                                        >
                                            <div
                                                data-thq="thq-dropdown-toggle"
                                                className="home-dropdown-toggle6"
                                            >
                                                <div onClick={() => history.push("/product-ups")}>
                                                    <span className="home-text07">
                                                        Electric Fire Prevention Device
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <img
                                alt="image"
                                src="/svgs/logo.svg"
                                loading="lazy"
                                className="home-image"
                            />
                            <div className="home-right">
                                <div onClick={() => history.push("/about")}>
                                    <span className="home-text08 navbar-link">ABOUT</span>
                                </div>
                                <span className="home-text09 navbar-link">CONTACT</span>
                            </div>
                        </div>
                        <div className="home-icons"></div>
                    </div>
                </div>
                <div data-role="BurgerMenu" className="home-burger-menu">
                    <svg viewBox="0 0 1024 1024" className="home-icon">
                        <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                </div>
                <div data-role="MobileMenu" className="home-mobile-menu">
                    <div className="home-nav">
                        <div className="home-container03">
                            <img
                                alt="image"
                                src="/svgs/logo.svg"
                                loading="lazy"
                                className="home-image"
                            />
                            <div
                                data-role="CloseMobileMenu"
                                className="home-close-mobile-menu"
                            >
                                <svg viewBox="0 0 1024 1024" className="home-icon02">
                                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="home-middle1">
                            <Link to="/">
                                <span className="home-text navbar-link">HOME</span>
                            </Link>
                            <span className="home-text11">PRODUCTS</span>
                            <Link to="about">
                                <span className="home-text08 navbar-link">ABOUT</span>
                            </Link>
                            <Link to="/">
                                <span className="home-text08 navbar-link">CONTACT</span>
                            </Link>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </header>
        </div>

    )

}

export default Navbar;