import React, { useState, useEffect } from 'react'

import { Helmet } from 'react-helmet';

import ItemTile from '../../components/item-tile'
import './product-ups.css'

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { getAllProductUPS } from '../../controllers/productUPSController';

const ProductUps = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await getAllProductUPS();
    console.log(response);
    setData(response);
  }
   

  return (
    <div className="product-ups-container">
      <Helmet>
        <title>UPS</title>
        <meta property="og:title" content="Product-Ups - Neha Energy" />
      </Helmet>
      <Navbar />
      <div className="product-ups-main">
        <div className="product-ups-hero section-container">
          <div className="product-ups-max-width max-width-container">
            <div className="product-ups-hero1">
              <ul className="product-ups-ul list">
                <li className="product-ups-li list-item">

                  {data.map((item, index) => <ItemTile rootClassName="item-tile-root-class-name1" item={item} key={index} />)}

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
}

export default ProductUps
