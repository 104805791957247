import { networkService } from '../networkService/networkService'; 

export const getAllProductUPS = async () => {

    let status = {};

    try {
      const result = await networkService.request({
        method: 'GET',
        url: "/product/ups",
      });
  
      if (result?.data) {
        status = result.data;
      }

    } catch (error) {
      status.error = error.data.error;
    }

    return status;
}