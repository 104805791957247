import React from 'react'

import './category-card.css'

const CategoryCard = (props) => {


  return (
    <div className={`category-card-category-card ${props.rootClassName} `}>
      <img
        loading="lazy"
        src={props.image_src}
        className="category-card-image"
      />
      <span className="category-card-text">{props.name}</span>
    </div>
  )
}

export default CategoryCard
